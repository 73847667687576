import { ProductBadges } from "@components/ui/product-badges/ProductBadges";
import { Text } from "@ui/components/content/text/Text";
import { Box } from "@ui/components/layout/box/Box";
import { Flex } from "@ui/components/layout/flex/Flex";
import { Grid } from "@ui/components/layout/grid/Grid";
import { NextImage } from "@ui/components/media-and-icons/image/NextImage";
import { Collapse } from "@ui/components/other/transitions/Collapse";
import { chakraRef } from "@ui/utils/forwardRef";
import { getImageAltTag } from "@ui/utils/productNameTransform";
import { useRouter } from "next/router";
import type { Dispatch, ReactElement, SetStateAction } from "react";
import { space } from "ui/src/design-tokens";
import type { Product, ProductTileProps } from "../ProductTile";

export interface ProductImagesProps {
    e;
    initialProduct: Product;
    displayedProduct: Product;
    productColors: ProductTileProps["productColors"];
    productName: ProductTileProps["productName"];
    setDisplayedProduct: Dispatch<SetStateAction<Product>>;
    isExpanded: boolean;
    onOpen: () => void;
    onClose: () => void;
    aspectRatioImage?: string | string[];
    imageBackgroundColor: string;
    altTagPattern?: string;
    tags: null | ReactElement;
    productSlug?: string;
    discountPercentage: number;
    customBadges?: string[];
}

export const ProductImages = chakraRef(
    (
        {
            setDisplayedProduct,
            displayedProduct,
            initialProduct,
            productColors,
            isExpanded,
            onOpen,
            onClose,
            aspectRatioImage,
            altTagPattern,
            productSlug,
            discountPercentage,
            customBadges,
        },
        ref
    ) => {
        const { push } = useRouter();
        const setCurrentProduct = (item: Product) => {
            setDisplayedProduct((prevProduct) => {
                return {
                    ...prevProduct,
                    priceWithDiscount: item.priceWithDiscount,
                    priceWithoutDiscount: item.priceWithoutDiscount,
                    image: item.image,
                    imageLabel: item.imageLabel,
                    objectID: item.objectID,
                    colorCode: item.colorCode,
                    altTagPattern: item.altTagPattern,
                    imageBackgroundColor: item.imageBackgroundColor,
                    discountPercentage: item.discountPercentage,
                    hasDiscount: item.hasDiscount,
                    hasStock: item.hasStock,
                };
            });
        };

        const setInitialProduct = () => {
            setDisplayedProduct(initialProduct);
        };

        const productColorsWithStock =
            productColors?.filter((item) => item.hasStock === true) ?? [];

        const additionalColors = `+${productColorsWithStock?.length - 5}`;

        const handleKeyDown = (e: React.KeyboardEvent) => {
            if (e.key === "Enter" || e.key === " ") {
                e.preventDefault();
                e.stopPropagation();
                push(productSlug as string);
            }
        };

        return (
            <>
                <Box
                    onMouseEnter={onOpen}
                    onMouseLeave={onClose}
                    sx={{ aspectRatio: aspectRatioImage, position: "relative" }}
                >
                    <NextImage
                        src={displayedProduct.image}
                        sizes={["100vw", "100vw", "33vw"]}
                        alt={getImageAltTag(altTagPattern, displayedProduct.imageLabel)}
                        justifyContent="center"
                        sx={{
                            aspectRatio: ["4/5"],
                            display: "flex",
                            alignItems: "flex-end",
                        }}
                        backgroundColor={
                            isExpanded ? "grayHover" : displayedProduct.imageBackgroundColor
                        }
                        fill={true}
                    />
                    {(discountPercentage > 0 || customBadges.length > 0) && (
                        <ProductBadges
                            discount={discountPercentage > 0 && `-${discountPercentage}%`}
                            customBadges={customBadges.length ? customBadges : []}
                        />
                    )}
                </Box>
                <Box
                    display={["none", "block"]}
                    pl={[4, 4, 4, 6]}
                    w="full"
                    onMouseEnter={onOpen}
                    onMouseLeave={onClose}
                    ref={ref}
                    pt={isExpanded ? 3 : 0}
                    transition="padding-top 0.3s"
                >
                    <Collapse
                        transition={{ enter: { duration: 0.3 }, exit: { duration: 0.3 } }}
                        in={isExpanded}
                        animateOpacity
                        startingHeight={0.01}
                    >
                        <Grid
                            templateColumns="repeat(auto-fit, minmax(15%, 15%))"
                            h="full"
                            gap={space["0.2"]}
                        >
                            {[
                                initialProduct,
                                ...(productColorsWithStock || [])
                                    .sort((a, b) => Number(b.hasStock) - Number(a.hasStock))
                                    .slice(0, 5),
                            ].map((item, index) => {
                                return (
                                    <Box
                                        key={item?.objectID}
                                        gridColumn={index + 1}
                                        onMouseEnter={() => setCurrentProduct(item)}
                                        onMouseLeave={setInitialProduct}
                                    >
                                        <NextImage
                                            sx={{
                                                aspectRatio: ["4/5"],
                                                display: "flex",
                                                alignItems: "flex-end",
                                            }}
                                            src={item?.image}
                                            alt={getImageAltTag(altTagPattern, item?.imageLabel)}
                                            sizes={["15vw"]}
                                            quality={50}
                                            role="link"
                                            tabIndex={0}
                                            onKeyDown={handleKeyDown}
                                            onFocus={() => setCurrentProduct(item)}
                                            onBlur={setInitialProduct}
                                            _focusVisible={{
                                                outline: "2px solid black",
                                                zIndex: 2,
                                                outlineOffset: "-2px",
                                            }}
                                            backgroundColor={"grayHover"}
                                            fill={true}
                                        />
                                    </Box>
                                );
                            })}
                            {productColorsWithStock.length > 5 && (
                                <Flex gridColumn={7} justifyContent="center" alignItems="center">
                                    <Text size="large" fontWeight="bold" color="black">
                                        {additionalColors}
                                    </Text>
                                </Flex>
                            )}
                        </Grid>
                    </Collapse>
                </Box>
            </>
        );
    }
);

ProductImages.displayName = "ProductImages";
