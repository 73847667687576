import type { HTMLChakraProps } from "@chakra-ui/react";
import { chakra, forwardRef } from "@chakra-ui/react";
import { cx } from "@chakra-ui/utils";
import type { AlgoliaMoneyData, CommercetoolsMoneyData, SimpleMoneyData } from "./useMoney";
import { useMoney } from "./useMoney";
import { useMemo } from "react";

interface MoneyOptions {
    locale: string;
    data: CommercetoolsMoneyData | SimpleMoneyData | AlgoliaMoneyData;
    withoutCurrency?: boolean;
    withoutTrailingZeros?: boolean;
    convertFromCents?: boolean;
}

export interface MoneyProps extends Omit<HTMLChakraProps<"p">, "children">, MoneyOptions {}

export const Money = forwardRef<MoneyProps, "p">(
    (
        { locale, data, convertFromCents = true, withoutCurrency, withoutTrailingZeros, ...props },
        ref
    ) => {
        const moneyObj = useMoney({ locale, money: data, convertFromCents });

        const output = useMemo(() => {
            if (withoutCurrency || withoutTrailingZeros) {
                if (withoutCurrency && !withoutTrailingZeros) {
                    return moneyObj.amount;
                } else if (!withoutCurrency && withoutTrailingZeros) {
                    return moneyObj.withoutTrailingZeros;
                } else {
                    return moneyObj.withoutTrailingZerosAndCurrency;
                }
            }
            return moneyObj.localizedString;
        }, [moneyObj, withoutCurrency, withoutTrailingZeros]);

        return (
            <chakra.p
                data-privacy="true"
                ref={ref}
                {...props}
                className={cx("chakra-money", props.className)}
                __css={{
                    fontFeatureSettings: "pnum",
                    fontVariantNumeric: "proportional-nums",
                }}
                suppressHydrationWarning
            >
                {output}
            </chakra.p>
        );
    }
);
